import axios, { AxiosResponse } from 'axios';
import { jwtDecode } from 'jwt-decode';
import { debounce } from 'lodash';
import { Store } from 'redux';
import Cookies from 'universal-cookie';
import { AUTHENTICATION, broadcast } from '../broadcastChannels/channels';
import { REMEMBER_ME, SIGN_IN, SIGN_OUT } from '../redux/actions/types';
import store from '../redux/configureStore';
import { INITIAL_AUTH_STATE } from '../redux/reducers/userAuthReducer';
import {
  AUTH_LEVEL,
  AUTH_STATUS,
  COOKIE_AUTH_LEVEL,
  COOKIE_REMEMBER_ME,
  COOKIE_TOKEN,
  CSRF_TOKEN,
  Credentials,
  RegisterResponse,
  TokenRefreshResponse
} from './authTypes';

const cookies = new Cookies();

/**
 * Called by broadcast handler and updates app auth state.
 * @param {any} data Passed by broadcast handler.
 * @param {boolean} isSignedIn Whether state is signed in or out.
 */
export const handleAuthStateUpdate = (data: any) => {
  if (data.signedIn) {
    removeCSRFToken();
    store.dispatch({
      type: SIGN_IN,
      payload: {
        isSignedIn: true,
        user: {},
        authLevel: AUTH_LEVEL.FULL,
        errors: INITIAL_AUTH_STATE.errors
      }
    });
  }
  if (data.signOut) {
    store.dispatch({ type: SIGN_OUT });
  }
};

/**
 * Checks to see if token will expire
 * in next 5 mins.
 *
 * @param {string} token Token to check
 * @return {boolean}
 */
const isTokenExpired = (token: string) => {
  const decoded: any = jwtDecode(token);
  // We state token has expried 5 mins before it does.
  if (Date.now() >= (decoded.exp - 300) * 1000) {
    return true;
  }
  return false;
};

/**
 * Creates a cookie with token that
 * expires 10 mins before token.
 *
 * @param {string} token Token to add to the cookie.
 */
const setTokenCookie = (token: string) => {
  cookies.set(COOKIE_TOKEN, token, {
    sameSite: 'strict',
    secure: true,
    path: '/'
  });
};

/**
 * Returns a token if it can
 *
 * @return {string}
 */
export const getTokenCookie = () => {
  return cookies.get(COOKIE_TOKEN);
};

/**
 * Removes the token
 */
const removeTokenCookie = () => {
  cookies.remove(COOKIE_TOKEN, { sameSite: 'strict', path: '/' });
};

export const setAuthCookies = (token: string, authLevel: AUTH_LEVEL, rememberMe: boolean) => {
  setTokenCookie(token);
  setAuthLevelCookie(authLevel);
  setRememberMeCookie(rememberMe);
};

const getRememberMeCookie = () => {
  return cookies.get(COOKIE_REMEMBER_ME);
};

export const getRememberMe = () => {
  return getRememberMeCookie() ? true : false;
};

const setRememberMeCookie = (rememberMe: boolean) => {
  cookies.set(COOKIE_REMEMBER_ME, rememberMe, {
    sameSite: 'strict',
    secure: true,
    expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
    path: '/'
  });
};

/**
 * Removes the auth level cookie
 */
export const removeRememberMeCookie = () => {
  cookies.remove(COOKIE_REMEMBER_ME, { sameSite: 'strict', path: '/' });
};

export const removeAuthCookies = () => {
  removeTokenCookie();
  removeAuthLevelCookie();
  removeRememberMeCookie();
  removeCSRFToken();
};

export const removeCSRFToken = () => {
  sessionStorage.removeItem(CSRF_TOKEN);
};
/**
 * Returns auth level if it can
 *
 * @return {string}
 */
export const getAuthLevel = () => {
  return cookies.get(COOKIE_AUTH_LEVEL);
};

/**
 * Creates an auth level cookie.
 *
 * @param {level} level Arbritary auth level.
 */
const setAuthLevelCookie = (level: string) => {
  cookies.set(COOKIE_AUTH_LEVEL, level, {
    sameSite: 'strict',
    secure: true,
    path: '/'
  });
};

/**
 * Removes the auth level cookie
 */
export const removeAuthLevelCookie = () => {
  cookies.remove(COOKIE_AUTH_LEVEL, { sameSite: 'strict', path: '/' });
};

export const handleRememberMeRefresh = async (store: Store) => {
  const response = await refreshToken();
  if (response.success) {
    setAuthCookies(response.token, getAuthLevel() ?? AUTH_LEVEL.PARTIAL, true);
    store.dispatch({ type: REMEMBER_ME, payload: {} });
  }
};

/**
 * Attempts to return a token. Initially from the
 * cookie, and if not possible then from backend.
 *
 * @param {boolean} forceFresh Forces the token to come from the backend.
 *
 * @return {Promise<TokenRefreshResponse>}
 */
export const refreshToken = async (forceFresh: boolean = false): Promise<TokenRefreshResponse> => {
  // if we are not forcing a refresh, check for a cookie token first.
  if (!forceFresh) {
    const token = getTokenCookie();
    if (token && token !== 'null' && !isTokenExpired(token)) {
      return { success: true, token: token, status: 0 };
    }
  }
  // Attempt to refresh the token
  try {
    const response: AxiosResponse = await axios.post(
      '/api/refreshToken',
      {},
      {
        validateStatus: (status) => {
          return status == 200;
        }
      }
    );

    switch (response.status) {
      case 200: {
        const newToken = response.data.token;
        if (!newToken) {
          // @TODO: need to handle the rejection.
          return { success: false, token: '', status: -1 };
        }
        setTokenCookie(newToken);
        return { success: true, token: newToken, status: 200 };
      }
    }
    return { success: false, token: '', status: -1 };
  } catch (err) {
    return { success: false, token: '', status: -2 };
  }
  return { success: false, token: '', status: -3 };
};

/**
 * Attempts to sign in with credentials.
 *
 * @param {Credentials} credentials
 *
 * @return {any}
 */
export const handleSignIn = async ({ email, password, rememberMe }: Credentials) => {
  // Attempt to authenticate with backend.
  try {
    if (rememberMe == undefined) {
      const authLevel = getAuthLevel();
      if (authLevel == AUTH_LEVEL.PARTIAL) {
        rememberMe = getRememberMe();
      }
      if (rememberMe == undefined) {
        rememberMe = false;
      }
    }
    const response: AxiosResponse = await axios.post(
      `/api/login?remember_me=${rememberMe}`,
      {
        email: email,
        password: password
      },
      {}
    );
    if (response.status === 200) {
      removeCSRFToken();
      setAuthCookies(response.data.token, AUTH_LEVEL.FULL, rememberMe ?? false);
      broadcast(AUTHENTICATION, { signedIn: true });
      return { success: true, errorCode: null };
    }
  } catch (err: any) {
    switch (err.response.status) {
      case 429:
        return { success: false, errorCode: AUTH_STATUS.BRUTE_ERROR };
      default:
        return { success: false, errorCode: AUTH_STATUS.GENERAL_ERROR };
    }
  }
};

/**
 * Signs user out on backend and removes token.
 *
 * @return {boolean}
 */
export const handleSignOut = async () => {
  // Attempt to authenticate with backend.
  await axios.get('/api/logout').catch((error) => {
    return error;
  });
  removeAuthCookies();
  broadcast(AUTHENTICATION, { signOut: true });
  return true;
};

/**
 * Attempts to sign in with credentials.
 *
 * @param {any} values
 * @return {RegisterResponse}
 */
export const handleRegister = async (values: any): Promise<RegisterResponse> => {
  try {
    const rememberMe = values.rememberMe;

    const response: AxiosResponse = await axios.post('/api/register', values, {});
    if (response.status === 200) {
      setAuthCookies(response.data.token, AUTH_LEVEL.FULL, rememberMe ?? false);
      return { success: true, user: response.data.profile, error: '' };
    }
  } catch (err: any) {
    if (err.response.status == 406) {
      return { success: false, user: {}, error: err.response.data.info };
    }

    if (err.response.status == 403 && values.attempt == 1) {
      await getCSRFToken('/api/register', 'register');
      values.attempt = 2;
      return await handleRegister(values);
    }
  }
  return { success: false, user: {}, error: 'Unknown error' };
};

/**
 * Attempt to login and redirect user.
 *
 * @param {string} hash Link login hash.
 * @param {string} path Path to redirect user to.
 * @return {boolean}
 */
export const linkLoginRedirect = async (hash: string, path: string) => {
  try {
    const response = await axios.post(
      `/api/linklogin/${hash}`,
      {
        path: path
      },
      {}
    );
    if (response.status == 200) {
      setAuthLevelCookie(AUTH_LEVEL.PARTIAL);
      return { user: response.data.user, path: response.data.path };
    } else {
      removeAuthLevelCookie();
      return { user: null, path: null };
    }
  } catch (err) {
    return false;
  }
};

/**
 * Fetch the oauth user from session.
 *
 * @param {any} values to update user profile
 * @return {boolean | any}
 */
export const oauthUserFetch = async (type: string) => {
  try {
    const response = await axios.get(`/api/oauth/user/${type}`, {});
    if (response.status == 200 && response.data) {
      return { valid: true, data: response.data };
    }
    return { valid: false, data: null };
  } catch (err) {
    return false;
  }
};

/**
 * Makes a request to get a CSRF token.
 *
 * @param {string} path
 * @param {string} formId
 * @return {boolean}
 */
export const getCSRFToken = async (path: string, formId: string) => {
  try {
    const response = await axios.post(`/api/csrftoken`, {
      path: path,
      formId: formId
    });
    if (response.status != 200) {
      console.error('Unable to set token', path, formId);
      return false;
    }
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};

/**
 * Makes a request to get a CSRF token.
 * Debounce added to delay subsequent request that requires CSRF token
 * TODO review lock block in backend (for this endpoint)
 *
 * @return {Promise<string>}
 */
export const getAuthCSRFToken = debounce(
  async (): Promise<string> => {
    const token = sessionStorage.getItem(CSRF_TOKEN);
    const CSRF_TIMEOUT = 3600000;

    if (token) {
      const tokenInfo = JSON.parse(token);
      if (tokenInfo.CSRFTimeoutAT > new Date().getTime()) {
        return tokenInfo.token;
      }
    }

    try {
      const response = await axios.post(`/api/auth/csrftoken`);
      if (response.status != 200) {
        console.error('Unable to get token');
        return '';
      }

      const tokenInfo = {
        token: response.data.token,
        CSRFTimeoutAT: new Date().getTime() + CSRF_TIMEOUT
      };
      sessionStorage.setItem(CSRF_TOKEN, JSON.stringify(tokenInfo));
      return tokenInfo.token;
    } catch (err) {
      console.error(err);
      return '';
    }
  },
  1000,
  { leading: true, trailing: false }
);
