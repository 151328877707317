const TrackingEvents = {
  track: 'asyncTrackingEvent',
  queue: 'asyncTrackBacklog',
  scroll: 'scroll'
};
const abacusDataLayerName = 'abacus';

let lastPageLocation;
let scrollCollection = {};

const getScrollHeight = () => {
  return Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight,
    document.body.clientHeight,
    document.documentElement.clientHeight
  );
};

const getTrackingData = (data) => {
  const track = { data: {} };
  for (const property in data) {
    // eslint-disable-next-line no-prototype-builtins
    if (data.hasOwnProperty(property) && property !== 'track') {
      if (property === 'trackAbacus') {
        track.event = data[property];
      } else {
        property === 'trackUrl' &&
          data[property]?.indexOf('http') === -1 &&
          (data[property] = document.location.origin + data[property]);
        property === 'trackTarget' &&
          data[property]?.indexOf('http') === -1 &&
          (data[property] = document.location.origin + data[property]);
        track.data[property.replace('track', '').toLowerCase()] = data[property];
      }
    }
  }
  return track;
};

const getSpecificPageTrackingData = () => {
  const data = {};
  for (const property in document.body.dataset) {
    if (property.match(/^track/gm)) {
      data[snakeize(camelize(property.replace('track', '')))] = document.body.dataset[property];
    }
  }
  return data;
};

const camelize = (str) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
};

const snakeize = (str) => {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
};

const addAbacusDataLayerToWindow = (api, defaultDataLayer) => {
  // Create property on window object named abacus
  window[abacusDataLayerName] =
    window[abacusDataLayerName] ||
    (function (u, dp = {}) {
      const rv = {};
      let _page = { page: {} };
      rv.dataBacklog = [];
      rv.queueOnly = false;
      rv.push = function (...args) {
        if (this.queueOnly) {
          this.dataBacklog.push({ detail: { trackAbacus: args[0].event } });
          return;
        }
        _page = args[0].page ? { page: args[0].page } : _page;
        const _data = { ..._page, ...dp, ...args[0] };
        fetch(u, {
          method: 'POST',
          body: JSON.stringify(_data)
        }).then((response) => {
          if (process.env.NODE_ENV != 'production') {
            console.log('Abacus: ', _data);
            console.log('Abacus: ', response);
          }
        });
      };
      return rv;
    })(api, defaultDataLayer.value);
    if (process.env.NODE_ENV != 'production') {
      console.debug("Abacus tracking datalayer enabled", api, defaultDataLayer)
    }
};

const addAbacusScrollTracking = () => {
  const scrollThresholds = [
    { threshold: 10, event: TrackingEvents.scroll },
    { threshold: 25, event: TrackingEvents.scroll },
    { threshold: 50, event: TrackingEvents.scroll },
    { threshold: 75, event: TrackingEvents.scroll },
    { threshold: 90, event: TrackingEvents.scroll }
  ];

  /**
   * Initialisation
   */
  const initialCurrentPos = window.scrollY;
  const initialInnerHeight = window.innerHeight;
  const initialScrollHeight = getScrollHeight();
  const initialScrollDistance = initialScrollHeight - initialInnerHeight;
  const initialScrollPercentage = initialCurrentPos / (initialScrollDistance / 100);
  scrollThresholds.forEach((t) => {
    if (initialScrollPercentage > 0 && t.threshold <= initialScrollPercentage) {
      scrollCollection[t.threshold] = true;
    }
  });

  /**
   * Pagescroll
   */
  window.addEventListener(TrackingEvents.scroll, function () {
    const currentPos = window.scrollY;
    const innerHeight = window.innerHeight;
    const scrollHeight = getScrollHeight();
    const scrollDistance = scrollHeight - innerHeight;
    const scrollPercentage = currentPos / (scrollDistance / 100);
    scrollThresholds.forEach((t) => {  
      if (!scrollCollection[t.threshold]) {
        if (scrollPercentage > t.threshold) {
          const event = {
            trackAbacus: t.event,
            event_interaction: true,
            event_metric: t.threshold,
            scroll_units: 'percent',
            scroll_direction: 'vertical'
          };
          const specificData = getSpecificPageTrackingData();
          if (!specificData.disableScroll) {
            const trackingEvent = new CustomEvent(TrackingEvents.track, {
              detail: event
            });
            window.dispatchEvent(trackingEvent);
          }
          scrollCollection[t.threshold] = true;
        }
      }
    });
  });

    if (process.env.NODE_ENV != 'production') {
      console.debug("Abacus tracking scrolling enabled")
    }

};

const addAbacusClickTracking = () => {
  /**
   * Click tracking.
   */
  document.addEventListener('click', (event) => {
    if (event.target.dataset.trackAbacus) {
      const data = event.target.dataset;
      delete data.track;
      const trackingEvent = new CustomEvent(TrackingEvents.track, {
        detail: data
      });
      window.dispatchEvent(trackingEvent);
    }
  });
    if (process.env.NODE_ENV != 'production') {
      console.debug("Abacus tracking click enabled")
    }  
};

const addAbacusTrackingEvent = () => {
  /**
   * Aysnc tracking listener
   * event.details: {
   *    track?: string,
   *    trackAbacus?: string,
   *    trackId?: string,
   *    trackText?: string,
   *    trackUrl?: string,
   *    trackTarget?: string,
   *    trackValue?: any,
   *    trackMeta?: object
   * }
   */
  window.addEventListener(TrackingEvents.track, (event) => {
    if (!lastPageLocation) {
      window[abacusDataLayerName].dataBacklog.push(event);
      return false;
    }
    const w = window;
    const data = event.detail;
    const specificData = getSpecificPageTrackingData();

    if (w.dataLayer && data.track) {
      const event = { ...{ event: TrackingEvents.track }, ...data };
      w.dataLayer.push(event);
      w.dataLayer.push({
        track: undefined,
        trackAbacus: undefined,
        trackId: undefined,
        trackText: undefined,
        trackUrl: undefined,
        trackTarget: undefined,
        trackValue: undefined,
        trackMeta: undefined
      }); // Reset datalayer, as only reset on page change.
      if (process.env.NODE_ENV != 'production') {
        console.log('Event added to dataLayer', w.dataLayer);
      }
    }
    if (data.trackAbacus) {
      const trackingData = getTrackingData(data);
      const event = {
        event: data.trackAbacus,
        page: lastPageLocation,
        data: trackingData.data
      };
      Object.keys(specificData).length !== 0 && (event.data = { ...event.data, ...specificData });
      window[abacusDataLayerName].push(event);
    }
  });
    if (process.env.NODE_ENV != 'production') {
      console.debug("Abacus tracking event enabled")
    }  
};

const addAbacusEventQueue = () => {
  /**
   * Backlog, if we dont have the data needed to call an event, we wait till this event called before calling events.
   */
  window.addEventListener(TrackingEvents.queue, function () {
    if (window[abacusDataLayerName].dataBacklog && window[abacusDataLayerName].dataBacklog.length > 0) {
      for (let i = 0; i < window[abacusDataLayerName].dataBacklog.length; i++) {
        const trackingEvent = new CustomEvent(TrackingEvents.track, {
          detail: window[abacusDataLayerName].dataBacklog[i].detail
        });
        window.dispatchEvent(trackingEvent);
      }
    }
  });
};
// /**
//  *
//  * @param {*} e
//  */
// const loadScript = (e) => {
//   // save user's choice in a local variable
//   const consent = e.detail || {};

//   // check if our service has been accepted
//   if (consent[abacusDataLayerName]) {
//     // Execute any queued events.
//     window[abacusDataLayerName].queueOnly = false;
//     window.dispatchEvent(new CustomEvent(TrackingEvents.queue));
//   }
// };

exports.onRouteUpdate = (routing, { abacusEnabled }) => {
  const initialHistoryState = { key: Date.now() };
  window.chilliNavigationIndex++;

  if (abacusEnabled) {
    /**
     * Pageview
     */
    if (routing.location?.href != routing.prevLocation?.href) {
      scrollCollection = {};
      const key = Date.now();
      routing.prevLocation = lastPageLocation;
      setTimeout(function () {
        lastPageLocation = {
          page_key: routing.location?.state?.key ? key.toString() : initialHistoryState.key,
          page_url: routing.location?.href,
          page_title: document.title || 'Unknown',
          page_tab_id: initialHistoryState.key.toString(),
          page_referrer_key: routing.prevLocation?.page_key,
          page_referrer: routing.prevLocation?.page_url || document.referrer
        };
        const event = {
          trackAbacus: 'pageview'
        };
        const trackingEvent = new CustomEvent(TrackingEvents.track, {
          detail: event
        });
        window.dispatchEvent(trackingEvent);
      }, 50);
    }
  }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
exports.onClientEntry = (_, { api, defaultDataLayer, abacusEnabled }) => {
  // addXtremePush(window, document, 'xtremepush', process.env.XTREMEPUSH_SDK_PATH);

  /**
   * Chilli UI close listener
   * Used on full pages to return to the main site.
   */
  window.addEventListener('chilliUiClose', (event) => {
    const w = window;
    const data = event.detail;
    // if last page has a value or we are not currently on login/ page.
    if (w && w.chilliNavigationIndex > 0 && data.lastAction.lastPage != '' && data.lastAction.lastPage != undefined) {
      if (location.pathname == '/forgot-password') {
        data.navigate('/login');
      } else {
        data.navigate(data.lastAction.lastPage);
      }
    } else {
      if (data.navigate) {
        data.navigate('/');
      } else {
        w.location.href = '/';
      }
    }
  });

  // Enable abacus tracking functionality.
  if (abacusEnabled) {

    if (process.env.NODE_ENV != 'production') {
      console.debug("Abacus tracking enabled")
    }
    addAbacusDataLayerToWindow(api, defaultDataLayer);
    addAbacusScrollTracking();
    addAbacusClickTracking();
    addAbacusTrackingEvent();
    addAbacusEventQueue();

    // Execute the callback function when banner sends consent events
    // window.addEventListener('cf_services_consent', loadScript);
    // window.addEventListener('cf_services_consent_loaded', loadScript);
  }
};
